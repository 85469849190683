import {
  IIntergroupRuleObj,
  IIntragroupRuleObj,
  ILabel,
  IntragroupCriteria,
  RulePreference,
  RulePreferenceType,
  RuleType,
} from './CustomRules/types'

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

function translatePreference(
  rulePreferenceType: RulePreferenceType,
  preference: RulePreference,
) {
  if (rulePreferenceType === RulePreferenceType.rule) {
    return preference === RulePreference.always ? 'Always' : 'Never'
  } else {
    return preference === RulePreference.always
      ? 'Preferably'
      : 'Preferably do not'
  }
}

function translateTarget(labels: ILabel[] | null | undefined) {
  if (!labels || labels.length === 0) {
    return 'everyone'
  }

  return labels.map((label) => capitalize(label.name)).join(' ')
}

function translateIntergroupRuleToReadableFormat(rule: IIntergroupRuleObj) {
  const target1 = translateTarget(rule.rule_data?.target_1)
  const target2 = translateTarget(rule.rule_data?.target_2)

  return `${translatePreference(
    rule.rule_preference_type,
    rule.preference,
  )} match ${target1} with ${target2}`
}

function translateCriteria(criteria: IntragroupCriteria) {
  switch (criteria) {
    case IntragroupCriteria['same location']:
      return 'in the same location'
    case IntragroupCriteria['same business unit']:
      return 'in the same business unit'
    case IntragroupCriteria['same title']:
      return 'with the same title'
    case IntragroupCriteria['same time zone']:
      return 'in the same time zone'
    default:
      return ''
  }
}

function translateIntragroupRuleToReadableFormat(rule: IIntragroupRuleObj) {
  const target1 = translateTarget(rule.rule_data?.target_1)

  return `${translatePreference(
    rule.rule_preference_type,
    rule.preference,
  )} match applicants ${translateCriteria(
    rule.rule_data?.criteria,
  )} for ${target1}`
}

export default function translateRuleToReadableFormat(
  rule: IIntergroupRuleObj | IIntragroupRuleObj,
) {
  if (rule.group_type === RuleType.intergroup) {
    return translateIntergroupRuleToReadableFormat(rule as IIntergroupRuleObj)
  } else {
    return translateIntragroupRuleToReadableFormat(rule as IIntragroupRuleObj)
  }
}
