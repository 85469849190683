import Axios from 'axios'
import { useCallback, useEffect, useState } from 'react'

import { EditMatchReasoningModal } from './Modals'
import { IMatchDetails } from './types'
import Button, { ButtonComponentType, Variant } from 'components/Button'
import _ from 'lodash'
import { useMentorshipExchangeDetailsContext } from './MentorshipExchangeDetailsContext'
import Spinner from 'images/icons/spinner.svg'
import Container from 'components/Container/Container'
import DownloadIcon from 'images/icons/download.svg'
import EditIcon from 'images/icons/edit.svg'
import { css } from '@emotion/react'

interface IMatchDetailsCellProps {
  data: IMatchDetails
}

interface OnActionResponse {
  data?: Record<string, any>
}

const iconStyle = css({
  width: 14,
  height: 14,
  marginRight: 4,
})

async function onAction(
  url: string,
  params?: Record<string, any>,
): Promise<OnActionResponse> {
  try {
    return await Axios.post(url, {
      authenticity_token: window.authenticity_token,
      ...params,
    })
  } catch (e) {
    window.flash('Something went wrong', 'alert')
    return Promise.resolve({})
  }
}

export default function MatchDetailsCell({
  data,
}: IMatchDetailsCellProps): JSX.Element {
  const { matchesLoading } = useMentorshipExchangeDetailsContext()

  if (matchesLoading[data.id]) {
    return (
      <Spinner
        css={{
          width: 20,
          height: 20,
        }}
      />
    )
  }

  const [openEditMatchReasoningModal, setOpenEditMatchReasoningModal] =
    useState(false)

  const [useData, setUseData] = useState(data)
  useEffect(() => {
    setUseData(data)
  }, [data])

  const [lastReasoning, setLastReasoning] = useState<string>(null)
  useEffect(() => {
    setLastReasoning(data.match_reasoning)
  }, [data])

  const updateReasoning = useCallback(
    (reason: string) => {
      const newData = _.cloneDeep(useData)
      setLastReasoning(newData.match_reasoning)
      newData.match_reasoning = reason
      setUseData(newData)
    },
    [useData],
  )
  return (
    <Container direction="column" alignment="start">
      {data.matchable && (
        <Button
          variant={Variant.LINK}
          onClick={() => setOpenEditMatchReasoningModal(true)}
          className="ignore-goji"
          startIcon={<EditIcon css={iconStyle} />}>
          Match Reasoning
        </Button>
      )}
      <Button
        as={ButtonComponentType.LINK}
        variant={Variant.LINK}
        startIcon={<DownloadIcon css={iconStyle} />}
        href={data.pair_csv}>
        Pair CSV
      </Button>
      {data.matchable && (
        <EditMatchReasoningModal
          key={useData.id}
          isOpen={openEditMatchReasoningModal}
          regenerateReasonUrl={useData.regenerate_reason_url}
          updateReasoning={updateReasoning}
          matchReasoning={useData.match_reasoning}
          manuallyMatched={useData.manually_matched}
          matchedBy={useData.manually_matched_by}
          partner1Name={useData.participant_1}
          partner2Name={useData.participant_2}
          onRequestClose={() => setOpenEditMatchReasoningModal(false)}
          onSave={(matchReasoning) => {
            onAction(useData.update_match_reasoning_url, {
              match_reasoning: matchReasoning,
            }).then((result: OnActionResponse) => {
              const { data } = result
              if (data['success'] === false) {
                updateReasoning(lastReasoning)
                window.flash(
                  'The program is currently locked. Therefore you cannot edit the match reasoning.',
                  'alert',
                )
              }
            })
            setOpenEditMatchReasoningModal(false)
          }}
        />
      )}
    </Container>
  )
}
