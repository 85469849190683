import CustomRulesComponent from './CustomRulesComponent'
import { CustomRulesProvider } from './CustomRulesContext'
import { IIntergroupRuleObj, IIntragroupRuleObj, ILabel } from './types'

interface ICustomRulesProps {
  customRules?: Array<IIntergroupRuleObj | IIntragroupRuleObj>
  labels?: ILabel[]
  errors?: Record<string, string[]>
}

export default function CustomRules({
  customRules,
  labels,
  errors,
}: ICustomRulesProps): JSX.Element {
  return (
    <CustomRulesProvider
      defaultCustomRules={customRules}
      labels={labels}
      errors={errors}>
      <CustomRulesComponent />
    </CustomRulesProvider>
  )
}
