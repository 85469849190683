import Axios from 'axios'
import { useMemo, useState } from 'react'
import { css } from '@emotion/react'

import Button, { ButtonComponentType, Variant } from 'components/Button'
import ApplicantsTable from './ApplicantsTable'

import {
  PeopleWidget,
  AcceptedMatchWidget,
  NotYetMatchedWidget,
  RemovedApplicantWidget,
} from 'components/Widget'
import EyeIcon from 'images/icons/eye.svg'
import DownloadIcon from 'images/icons/download.svg'
import { spacings, colors, sizes } from 'stylesheets/theme'

import MentorshipExchangeHeader from './MentorshipExchangeHeader'
import { useMentorshipExchangeDetailsContext } from './MentorshipExchangeDetailsContext'
import MentorshipExchangeDotMenu from './MentorshipExchangeDotMenu'
import MatchesForReviewTable from './MatchesForReviewTable'
import { useShiftKeys } from 'hooks/useShiftKey'
import Switch from 'components/Switch'
import PendingApprovalWidget from 'components/Widget/PendingApprovalWidget'
import PendingManagerApprovalTable from './PendingManagerApprovalTable'
import MatchResultsPagePrimaryButtonComponent from './MatchResultsPagePrimaryButtonComponent'
import NotYetMatchedTable from './NotYetMatchedTable'

const headerCss = css({
  '.main.with-sidebar > .content &, .main.without-sidebar > .content &': {
    flexDirection: 'column',
    alignItems: 'start',
  },
})

const headerSectionCss = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const buttonsContainerCss = css({
  display: 'flex',
  flexDirection: 'row',
  gap: '1rem',
})

const tablesCss = css({
  marginTop: '1rem',
  '.GojiCustomTable thead tr th:first-of-type': {
    width: 400,
  },
  '.debug-section': {
    paddingTop: '3rem',
    '.list-heading::after': {
      content: '" (testing list)"',
    },
  },
  '.link.remove': {
    color: colors.red,
  },

  '.applicants-table': {
    marginTop: spacings.grid_gap_basis_num * 4,
    '.GojiCustomTable': {
      tbody: {
        tr: {
          minHeight: sizes.tableRowMinHeight,
        },
      },
      tfoot: {
        tr: {
          minHeight: sizes.tableRowMinHeight,
        },
      },
    },
    '&.no-pagination': {
      '.applicants-table-pagination': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },
})

const matchResultsSummariesCss = css({
  display: 'flex',
  flexDirection: 'row',
  gap: spacings.widget_gap,
  '> div.widget': {
    flexGrow: 1,
    maxWidth: `calc((100% - ${spacings.widget_gap * 2}px) / 4)`,
  },
})

const programClosedSummariesCss = css({
  '> div.widget': {
    maxWidth: `calc((100% - ${spacings.widget_gap * 2}px) / 3)`,
  },
})

const MATCHES_REVIEW_TABLE = 'matches_review'
const MATCHES_ACCEPTED_TABLE = 'matches_accepted'
const NOT_YET_MATCHED_PARTICIPANTS_TABLE = 'not_yet_matched'
const REMOVED_PARTICIPANTS_TABLE = 'matches_removed'

async function onAction(
  url: string,
  params?: Record<string, any>,
): Promise<void> {
  try {
    await Axios.post(url, {
      authenticity_token: window.authenticity_token,
      ...params,
    })

    location.reload()
  } catch (e) {
    window.flash('Something went wrong', 'alert')
  }
}

interface IMatchResultsPageComponentProps {
  className?: string
}

export default function MatchResultsPageComponent({
  className = '',
}: IMatchResultsPageComponentProps): JSX.Element {
  const {
    exchange,
    tables,
    counts,
    urls,
    updateTableData,
    updateTableFilters,
    displayPendingApproval,
  } = useMentorshipExchangeDetailsContext()

  // Status detections
  const programClosed = useMemo(
    () => exchange.data.status == 'application_complete',
    [exchange],
  )

  // Overriding locks as mega-super admin
  const [isShiftKeyDown, setIsShiftKeyDown] = useState(false)
  useShiftKeys({
    onDown: () => {
      setIsShiftKeyDown(true)
    },
    onUp: () => {
      setIsShiftKeyDown(false)
    },
  })

  return (
    <>
      <div className="header" css={headerCss}>
        <div className="section-header" css={headerSectionCss}>
          <div className="header-left">
            <p className="section-title">Program Overview</p>
          </div>
          <div css={buttonsContainerCss}>
            <Button
              as={ButtonComponentType.LINK}
              className="submit-form-button link"
              startIcon={<EyeIcon />}
              href={urls.view_application}
              target="_blank">
              View application form
            </Button>
            <Button
              as={ButtonComponentType.LINK}
              variant={Variant.SECONDARY}
              href={urls.export_emails}>
              Export participants.csv
            </Button>
            <Button
              as={ButtonComponentType.LINK}
              variant={Variant.SECONDARY}
              href={urls.custom_matching_rules_page}>
              Set custom rules
            </Button>
            <MatchResultsPagePrimaryButtonComponent />
            <MentorshipExchangeDotMenu type="post-matching" />
          </div>
        </div>
        <MentorshipExchangeHeader exchange={exchange} />
      </div>
      <div
        css={
          programClosed
            ? [matchResultsSummariesCss, programClosedSummariesCss]
            : matchResultsSummariesCss
        }>
        {!programClosed && displayPendingApproval && (
          <PendingApprovalWidget
            numPending={counts.pending_approval}
            title="Pending manager approval"
            noZero
          />
        )}
        {!programClosed && (
          <PeopleWidget
            numPeople={counts[MATCHES_REVIEW_TABLE]}
            title="Matches for review"
            noZero
          />
        )}
        <AcceptedMatchWidget
          matches={counts[MATCHES_ACCEPTED_TABLE]}
          title="Accepted matches"
          noZero
        />
        <NotYetMatchedWidget
          numPeople={counts[NOT_YET_MATCHED_PARTICIPANTS_TABLE]}
          title="Not yet matched"
          noZero
        />
        <RemovedApplicantWidget
          numPeople={counts[REMOVED_PARTICIPANTS_TABLE]}
          title="Removed applicants"
          noZero
        />
      </div>
      <div
        className={`${className} mentorship-exchange-applicant-tables`}
        css={tablesCss}>
        {!programClosed && displayPendingApproval && (
          <PendingManagerApprovalTable
            pendingManagerApprovalTableProps={tables.pending_manager_approval}
            onAction={onAction}
            sendApprovalreminderUrl={urls.send_manager_approval_reminder}
            lastSentAt={exchange.data.last_manager_approval_reminder_sent_at}
          />
        )}
        {counts[MATCHES_REVIEW_TABLE] > 0 && (
          <MatchesForReviewTable
            matchesReviewTableProps={tables.matches_review}
            onAction={onAction}
            rejectAllMatchesUrl={urls.reject_all_matches}
            rejectSelectedMatchesUrl={urls.reject_selected_matches}
            acceptSelectedMatchesUrl={urls.accept_selected_matches}
          />
        )}
        <ApplicantsTable
          {...tables.matches_accepted}
          afterUpdateTableData={(data) => {
            updateTableData('matches_accepted', data)
          }}
          afterUpdateSelectedFilters={(filters) => {
            updateTableFilters('matches_accepted', filters)
          }}
          emptyStateChildren={
            <p css={{ fontWeight: 'normal' }}>No matches accepted yet</p>
          }
          leftNode={
            <a href={urls.accepted_matches_rule_summary} className="link">
              <DownloadIcon />
              Export rule summary
            </a>
            // <Button
            //   as={ButtonComponentType.LINK}
            //   variant={Variant.LINK}
            //   startIcon={<DownloadIcon />}
            //   href={urls.accepted_matches_rule_summary}>
            //   Export rule summary
            // </Button>
          }
          secondaryActionNode={({ handleFilterTableRows }) => (
            <Switch
              css={{
                gap: spacings.grid_gap_basis,
              }}
              controlName="favorites_toggle"
              defaultChecked={false}
              uncheckedText="Show favorites"
              checkedText="Hide favorites"
              onChange={(e) => {
                const hideFavorite = e.target.checked
                handleFilterTableRows(
                  { hide: hideFavorite ? 'favorite' : undefined },
                  hideFavorite ? undefined : 'favorite',
                )
              }}
            />
          )}
        />
        <NotYetMatchedTable
          notYetMatchedTableProps={tables.not_yet_matched}
          programs={exchange.programs}
          programClosed={programClosed}
          isShiftKeyDown={isShiftKeyDown}
          startMatchingUrl={urls.start_rematching}
          rematchSelectedUrl={urls.rematch_selected}
          transferApplicationsUrl={urls.copy_applications_to}
        />
        <ApplicantsTable
          {...tables.matches_removed}
          css={{
            '.GojiCustomTable thead tr th:last-of-type': {
              width: '10%',
            },
          }}
          afterUpdateTableData={(data) => {
            updateTableData('matches_removed', data)
          }}
          afterUpdateSelectedFilters={(filters) => {
            updateTableFilters('matches_removed', filters)
          }}
          emptyStateChildren={
            <p css={{ fontWeight: 'normal' }}>No applicant has been removed</p>
          }
        />
      </div>
    </>
  )
}
