import { RuleArray, RulePreferenceType } from "./types";

export const sortRules = (rules: RuleArray) =>
  rules.sort((a, b) => a.priority_order - b.priority_order)

/**
 * Given a combined array of rules and preferences, separate them into two arrays then sort them by priority_order
 * Return an array of rules and preferences. Rules always come first.
 * @param rules 
 */
export const sortRulesAndPreferences = (combinedRules: RuleArray) => {
  const rules = combinedRules.filter(rule => rule.rule_preference_type === RulePreferenceType.rule)
  const preferences = combinedRules.filter(rule => rule.rule_preference_type === RulePreferenceType.preference)

  return [...sortRules(rules), ...sortRules(preferences)]
}