import React, {
  createContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react'
import {
  IIntergroupRule,
  IIntergroupRuleObj,
  IIntragroupRule,
  IIntragroupRuleObj,
  ILabel,
  IntragroupCriteria,
  RuleArray,
  RulePreference,
  RulePreferenceType,
  RuleType,
  TLabelOption,
} from './types'
import { IOption } from 'components/Inputs/Select'
import { sortRules } from './helper'

interface CustomRulesContextProps {
  customRules: RuleArray
  labelOptions: TLabelOption[]
  setRules: (rules: RuleArray) => void
  setPreferences: (preferences: RuleArray) => void
  addCustomRule: (
    rulePreferenceType: RulePreferenceType,
    ruleType: RuleType,
  ) => void
  removeCustomRule: (
    index: number,
    rulePreferenceType: RulePreferenceType,
  ) => void
  defaultEmptyInterGroupRule: (
    preferenceType: RulePreferenceType,
  ) => IIntergroupRuleObj
  defaultEmptyIntraGroupRule: (
    preferenceType: RulePreferenceType,
  ) => IIntragroupRuleObj
  rules: (IIntergroupRuleObj | IIntragroupRuleObj)[]
  preferences: (IIntergroupRuleObj | IIntragroupRuleObj)[]
  criteriaOptions: IOption[]
  updateRule: (
    rulePreferenceType: RulePreferenceType,
    index: number,
    preference: RulePreference,
    ruleData: IIntergroupRule | IIntragroupRule,
  ) => void
  errors: Record<string, string[]>
}

const CustomRulesContext = createContext<CustomRulesContextProps | undefined>(
  undefined,
)

interface ICustomRulesProviderProps {
  defaultCustomRules: RuleArray
  labels: ILabel[]
  errors: Record<string, string[]>
  children: JSX.Element
}

const CustomRulesProvider: React.FC<ICustomRulesProviderProps> = ({
  defaultCustomRules,
  labels,
  errors,
  children,
}) => {
  const [customRules] = useState<RuleArray>(defaultCustomRules || [])
  const [rules, setRules] = useState<RuleArray>([])
  const [preferences, setPreferences] = useState<RuleArray>([])

  // Separate rules and preferences
  useEffect(() => {
    const sortedRules = sortRules(customRules)
    setRules(
      sortedRules.reduce((acc, rule) => {
        if (rule.rule_preference_type === RulePreferenceType.rule) {
          acc.push({
            ...rule,
            hidden: rule._destroy,
            title:
              rule.group_type === RuleType.intergroup
                ? 'Intergroup'
                : 'Intragroup',
          })
        }
        return acc
      }, []),
    )
    setPreferences(
      sortedRules.reduce((acc, rule) => {
        if (rule.rule_preference_type === RulePreferenceType.preference) {
          acc.push({
            ...rule,
            hidden: rule._destroy,
            title:
              rule.group_type === RuleType.intergroup
                ? 'Intergroup'
                : 'Intragroup',
          })
        }
        return acc
      }, []),
    )
  }, [customRules])

  const defaultEmptyInterGroupRule = useCallback(
    (preferenceType: RulePreferenceType) =>
      ({
        id: 0,
        priority_order:
          preferenceType === RulePreferenceType.rule
            ? rules.length
            : preferences.length,
        rule_preference_type: preferenceType,
        preference: null,
        group_type: RuleType.intergroup,
        rule_data: {
          target_1: [],
          target_2: [],
        },
        title: 'Intergroup',
      } as IIntergroupRuleObj),
    [rules, preferences],
  )

  const defaultEmptyIntraGroupRule = useCallback(
    (preferenceType: RulePreferenceType) =>
      ({
        id: 0,
        priority_order:
          preferenceType === RulePreferenceType.rule
            ? rules.length
            : preferences.length,
        rule_preference_type: preferenceType,
        preference: null,
        group_type: RuleType.intragroup,
        rule_data: {
          criteria: null,
          target_1: [],
        },
        title: 'Intragroup',
      } as IIntragroupRuleObj),
    [rules, preferences],
  )

  const labelOptions = useMemo(() => {
    return (
      labels?.map((label) => ({
        value: `${label.object_type}.${label.object_id.toString()}`,
        label: label.name,
        labelType: label.object_type,
        labelId: label.object_id,
      })) || []
    )
  }, [labels])

  const criteriaLabel = (criteria: IntragroupCriteria) => {
    switch (criteria) {
      case IntragroupCriteria['same location']:
        return 'Match applicants in the same location'
      case IntragroupCriteria['same business unit']:
        return 'Match applicants in the same business unit'
      case IntragroupCriteria['same title']:
        return 'Match applicants with the same title'
      case IntragroupCriteria['same time zone']:
        return 'Match applicants in the same time zone'
      default:
        return ''
    }
  }

  const criteriaOptions: IOption[] = [
    {
      value: IntragroupCriteria['same location'].toString(),
      label: criteriaLabel(IntragroupCriteria['same location']),
    },
    {
      value: IntragroupCriteria['same business unit'].toString(),
      label: criteriaLabel(IntragroupCriteria['same business unit']),
    },
    {
      value: IntragroupCriteria['same title'].toString(),
      label: criteriaLabel(IntragroupCriteria['same title']),
    },
    {
      value: IntragroupCriteria['same time zone'].toString(),
      label: criteriaLabel(IntragroupCriteria['same time zone']),
    },
  ]

  const addCustomRule = useCallback(
    (rulePreferenceType: RulePreferenceType, ruleType: RuleType) => {
      const newRule =
        ruleType === RuleType.intergroup
          ? defaultEmptyInterGroupRule(rulePreferenceType)
          : defaultEmptyIntraGroupRule(rulePreferenceType)
      if (rulePreferenceType === RulePreferenceType.rule) {
        setRules([...rules, newRule])
      } else {
        setPreferences([...preferences, newRule])
      }
    },
    [rules, preferences],
  )

  const removeCustomRule = useCallback(
    (index: number, rulePreferenceType: RulePreferenceType) => {
      if (rulePreferenceType === RulePreferenceType.rule) {
        const updatedRules = [...rules]
        updatedRules[index]._destroy = true
        updatedRules[index].hidden = true
        setRules(updatedRules)
      } else {
        const updatedPreferences = [...preferences]
        updatedPreferences[index]._destroy = true
        updatedPreferences[index].hidden = true
        setPreferences(updatedPreferences)
      }
    },
    [rules, preferences],
  )

  const updateRule = useCallback(
    (
      rulePreferenceType: RulePreferenceType,
      index: number,
      preference: RulePreference,
      ruleData: IIntergroupRule | IIntragroupRule,
    ) => {
      if (rulePreferenceType === RulePreferenceType.rule) {
        const updatedRules = [...rules]
        updatedRules[index].preference = preference
        updatedRules[index].rule_data = ruleData
        setRules(updatedRules)
      } else {
        const updatedPreferences = [...preferences]
        updatedPreferences[index].preference = preference
        updatedPreferences[index].rule_data = ruleData
        setPreferences(updatedPreferences)
      }
    },
    [rules, preferences],
  )

  return (
    <CustomRulesContext.Provider
      value={{
        customRules,
        labelOptions,
        addCustomRule,
        removeCustomRule,
        setRules,
        setPreferences,
        defaultEmptyInterGroupRule,
        defaultEmptyIntraGroupRule,
        rules,
        preferences,
        criteriaOptions,
        updateRule,
        errors,
      }}>
      {children}
    </CustomRulesContext.Provider>
  )
}

export { CustomRulesProvider, CustomRulesContext }
