import { useMemo } from 'react'
import DeclinedIcon from 'images/icons/declined.svg'
import OpenIcon from 'images/icons/opendate.svg'
import { css } from '@emotion/react'
import { colors, fonts, spacings } from 'stylesheets/theme'
import { IMentorshipExchangePackage } from 'types'
import MentorshipExchangeStatusPill from 'components/MentorshipExchangeStatusPill/MentorshipExchangeStatusPill'
import { Paragraph } from 'components/Typography'

const timelineCss = css({
  color: colors.pink,
  fontFamily: fonts.poppins,
  fontSize: 18,
  lineHeight: '22px',
  marginTop: 13,
  svg: {
    verticalAlign: 'middle',
    'path[fill]': {
      fill: 'currentColor',
    },
    'path[stroke]:not([stroke*="#fff"])': {
      stroke: 'currentColor',
      strokeWidth: 1.8,
    },
  },
})
const openDateCss = css({
  marginTop: 19,
})

const pinkVariant = css({
  '.mentorship-program-title': {
    color: colors.pink,
  },
  '.mentorship-program-timeline span': {
    fontWeight: 400,
  },
})

const timelineWordsCss = css({
  display: 'inline-block',
  marginLeft: spacings.grid_gap_basis_num,
  color: colors.pink,
  fontFamily: 'Lato',
  fontSize: 19,
})

const titleAndStatusCss = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'start',
  gap: spacings.grid_gap_basis_num + 2,
})

export type HeaderVariant = 'standard' | 'modal'

interface IMentorshipExchangeHeaderProps {
  exchange: IMentorshipExchangePackage
  disableStatusPill?: boolean
  headerType?: 'h1' | 'h2' | 'h3'
  headerVariant?: HeaderVariant
  className?: string
}

export default function MentorshipExchangeHeader({
  exchange,
  disableStatusPill = false,
  headerType = 'h1',
  headerVariant = 'standard',
  className = '',
}: IMentorshipExchangeHeaderProps): JSX.Element {
  const opens = useMemo(
    () =>
      `${new Date(exchange.data.open_date_time).toLocaleString('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        timeZoneName: 'short',
      })}`,
    [exchange],
  )
  const deadlines = useMemo(
    () =>
      `${new Date(exchange.data.deadline_date_time).toLocaleString('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        timeZoneName: 'short',
      })}`,
    [exchange],
  )

  let header = null
  switch (headerType) {
    case 'h1':
      header = (
        <h1 className="mentorship-program-title">
          Mindr Mentorship Exchange {exchange.data.name}
        </h1>
      )
      break

    case 'h2':
      header = (
        <h2 className="mentorship-program-title">
          Mindr Mentorship Exchange {exchange.data.name}
        </h2>
      )
      break

    case 'h3':
      header = (
        <h3 className="mentorship-program-title">
          Mindr Mentorship Exchange {exchange.data.name}
        </h3>
      )
      break
  }

  const componentCss = []
  if (headerVariant === 'modal') {
    componentCss.push(pinkVariant)
  }

  return (
    <div className={`mentorship-program-name ${className}`} css={componentCss}>
      <div css={titleAndStatusCss}>
        {header}
        {disableStatusPill ? null : (
          <MentorshipExchangeStatusPill
            inline
            data={{
              status: exchange.data.status,
              matching_status: exchange.data.matching_status,
            }}
          />
        )}
      </div>
      <div
        className="mentorship-program-timeline open-date"
        css={[timelineCss, openDateCss]}>
        <OpenIcon />
        <Paragraph css={timelineWordsCss} bold>
          Application opens on {opens}
        </Paragraph>
      </div>
      <div
        className="mentorship-program-timeline deadline-date"
        css={timelineCss}>
        <DeclinedIcon />
        <Paragraph css={timelineWordsCss} bold>
          Application closes on {deadlines}
        </Paragraph>
      </div>
    </div>
  )
}
