import ReactPaginate from 'react-paginate'

// assets
import DownloadIcon from '../../../images/icons/download.svg'

// components
import TableHead from './TableHead'
import TableBody, { type ITableBodyProps } from './TableBody'

// contexts
import { useTableContext } from '../TableContext'
import Input from '../../Input'
import { spacings } from 'stylesheets/theme'
import Container from 'components/Container/Container'
import { useMemo } from 'react'

export interface TableComponentProps<T> {
  children?: ITableBodyProps<T>['children']
}

const TableComponent = <T,>({
  children,
}: TableComponentProps<T>): JSX.Element => {
  const {
    tableData: { rows, paginator },
    tableMeta: { searchable, exportable, url },
    title,
    searchInputValue,
    handlePaginate,
    onSearchInputChange,
    searchPlaceholder,
    showTotal,
    tableDescription,
    secondaryActionNode,
    leftNode,
    tertiaryActionNode,
    infoNode,
    alwaysShowSearch,
  } = useTableContext()

  const secondaryNodes = useMemo(() => {
    return (
      <div
        css={{
          display: 'flex',
          gap: spacings.grid_gap_basis_num * 2,
        }}
        className="secondary-nodes">
        {secondaryActionNode}
        {searchable && (
          <Input
            name="filter[*]"
            wrapperClassName="search"
            onChange={onSearchInputChange}
            value={searchInputValue}
            placeholder={searchPlaceholder}
          />
        )}
      </div>
    )
  }, [searchable, secondaryActionNode, searchInputValue, searchPlaceholder])

  const nonTitleSearch = useMemo(() => {
    if (!alwaysShowSearch) return null
    return <div className="section-header">{secondaryNodes}</div>
  }, [alwaysShowSearch, secondaryNodes])

  return (
    <>
      <div className="GojiCustomTable-header">
        {title ? (
          <div className="section-header">
            <div className="header-left">
              <p className="section-title">{title}</p>
              {showTotal && (
                <p className="section-total caption">
                  (Total {paginator.total_entries})
                </p>
              )}
              {exportable && !!rows.length && (
                <a href={url.replace('.json', '.csv')} className="link">
                  <DownloadIcon />
                  Export.csv
                </a>
              )}
              {!!rows.length && leftNode}
            </div>
            {secondaryNodes}
          </div>
        ) : (
          nonTitleSearch
        )}
      </div>
      {(!!tableDescription || !!tertiaryActionNode) && (
        <Container
          direction="row"
          alignment="start"
          css={{
            justifyContent: 'space-between',
          }}>
          {!!tableDescription && (
            <p className="GojiCustomTable-description">{tableDescription}</p>
          )}
          {!!tertiaryActionNode && (
            <div className="GojiCustomTable-tertiary">{tertiaryActionNode}</div>
          )}
        </Container>
      )}
      <div className="GojiCustomTable-wrapper">
        <table className="GojiCustomTable" cellPadding="0" cellSpacing="0">
          <TableHead />
          <TableBody>{children}</TableBody>
        </table>
      </div>
      {infoNode}
      {paginator && paginator.total_pages > 1 && (
        <div className="GojiCustomTable-pagination">
          <ReactPaginate
            previousLabel={''}
            nextLabel={''}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={paginator.total_pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePaginate}
            containerClassName={'pagination'}
            activeClassName={'active'}
            forcePage={paginator.current_page - 1}
          />
        </div>
      )}
    </>
  )
}

export default TableComponent
