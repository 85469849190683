import { css } from '@emotion/react'
import Button, { Variant } from 'components/Button/Button'
import AllMetIcon from 'images/icons/allmet.svg'
import NotMetIcon from 'images/icons/notmet.svg'
import { colors, spacings } from 'stylesheets/theme'
import { IMatchDetails } from './types'
import Modal, { Size } from 'components/Modal'
import { useMemo, useState } from 'react'
import { Paragraph, SectionTitle } from 'components/Typography'
import Container from 'components/Container'
import { sortRulesAndPreferences } from './CustomRules/helper'
import CheckIcon from 'images/icons/checked.svg'
import CloseIcon from 'images/icons/close.svg'
import translateRuleToReadableFormat from './helper'

const iconStyle = css({
  width: 18,
  height: 18,
})

const statusIconStyle = css({
  width: 14,
  height: 14,
})

const ruleMetIconStyle = css({
  path: {
    stroke: colors.green,
  },
})

const ruleBrokenIconStyle = css({
  path: {
    fill: colors.red,
  },
})

const allMetIconStyle = css({
  path: {
    fill: colors.green,
  },
})

const noneMetIconStyle = css({
  path: {
    fill: colors.red,
  },
})

const modalStyle = css({
  '& .mindr-modal-body': {
    marginTop: 0,
  },
})

export interface IRulesCellProps {
  data: IMatchDetails
}

export default function RulesCell({ data }: IRulesCellProps): JSX.Element {
  const [openRuleStatusModal, setOpenRuleStatusModal] = useState(false)

  // No rules were used for this match = no rules were set when match was created
  if (!data.rules_data) {
    return <>N/A</>
  }

  const customRulesSnapshotRules = useMemo(
    () =>
      data.rules_data?.custom_rules_snapshot?.rules
        ? JSON.parse(data.rules_data.custom_rules_snapshot.rules)
        : [],

    [data],
  )

  const rulesMetCount = data.rules_data?.rules_met?.length
  const totalRulesCount = customRulesSnapshotRules.length

  // No rules were met
  if (!rulesMetCount && totalRulesCount) {
    return <NotMetIcon css={[iconStyle, noneMetIconStyle]} />
  }

  // All rules were met
  if (rulesMetCount === totalRulesCount) {
    return <AllMetIcon css={[iconStyle, allMetIconStyle]} />
  }

  const sortedRules = sortRulesAndPreferences(customRulesSnapshotRules)
  return (
    <>
      <Button
        variant={Variant.LINK}
        onClick={() => setOpenRuleStatusModal(true)}>
        {rulesMetCount}/{totalRulesCount} met
      </Button>
      <Modal
        isOpen={openRuleStatusModal}
        largeTitle
        size={Size.wide}
        onSave={() => {}}
        title="Rules and preferences status"
        onRequestClose={() => setOpenRuleStatusModal(false)}
        cancelButton="close"
        css={modalStyle}>
        <Container direction="column" alignment="start">
          <Paragraph
            css={{
              marginBottom: spacings.grid_gap_basis_num * 2,
            }}>
            {rulesMetCount} of {totalRulesCount} met
          </Paragraph>
          <SectionTitle>Intergroup and intragroup</SectionTitle>
          {sortedRules?.map((rule) => (
            <Container key={rule.id}>
              {data.rules_data?.rules_met
                .map((ruleMet) => ruleMet.id)
                .includes(rule.id) ? (
                <CheckIcon css={[statusIconStyle, ruleMetIconStyle]} />
              ) : (
                <CloseIcon css={[statusIconStyle, ruleBrokenIconStyle]} />
              )}
              <Paragraph>{translateRuleToReadableFormat(rule)}</Paragraph>
            </Container>
          ))}
        </Container>
      </Modal>
    </>
  )
}
