import Button, { ButtonComponentType, Variant } from 'components/Button'
import Container from 'components/Container'
import DragAndDrop from 'components/DragAndDrop'
import PlusIcon from 'images/icons/plus.svg'
import {
  IIntergroupRule,
  IIntergroupRuleObj,
  IIntragroupRule,
  IIntragroupRuleObj,
  RulePreference,
  RulePreferenceType,
  RuleType,
  TLabelOption,
} from './types'
import { colors, spacings, zIndex } from 'stylesheets/theme'
import { css } from '@emotion/react'
import IntergroupCustomRuleItem from './IntergroupCustomRuleItem'
import { IOption } from 'components/Inputs/Select'
import IntragroupCustomRuleItem from './IntragroupCustomRuleItem'
import { Paragraph, SectionTitle } from 'components/Typography'
import { useEffect, useRef, useState } from 'react'
import { IDragAndDropProps } from 'components/DragAndDrop/DragAndDrop'

interface IRulesDndListProps {
  name: IDragAndDropProps['name']
  rules: (IIntergroupRuleObj | IIntragroupRuleObj)[]
  setRules: (rules: (IIntergroupRuleObj | IIntragroupRuleObj)[]) => void
  defaultItems: (IIntergroupRuleObj | IIntragroupRuleObj)[]
  labelOptions: TLabelOption[]
  criteriaOptions: IOption[]
  rulePreferenceType: RulePreferenceType
  onAddRule: (ruleType: RuleType) => void
  onRemoveRule: (index: number) => void
  updateRule: (
    index: number,
    preference: RulePreference,
    ruleData: IIntergroupRule | IIntragroupRule,
  ) => void
  errors: Record<string, string[]>
}

const dragAndDropStyle = css({
  marginLeft: -40,
  '& .draggable-container': {
    backgroundColor: colors.backgrounds.gray,
    border: 'none',
    borderRadius: spacings.radius,
    minWidth: 'fit-content',
    maxWidth: 1150,
    minHeight: 131,
  },
})

const plusIconStyle = css({
  marginRight: spacings.grid_gap_basis_num / 2,
  path: {
    stroke: colors.links.blue,
  },
})

const dropdownBoxStyle = css({
  width: '100%',
  gap: 0,
  borderRadius: spacings.radius,
  border: `0.5px solid ${colors.borders.gray}`,
  boxShadow: '0px 2px 6px 0px #0000001A',
  '.list-item:not(:first-child)': {
    borderTop: `0.5px solid ${colors.borders.gray}`,
  },
  maxWidth: 250,
  overflowY: 'auto',
  position: 'absolute',
  zIndex: zIndex.menu,
  backgroundColor: colors.backgrounds.white,
  marginTop: spacings.grid_gap_basis,
})

const listItemStyle = css({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  padding: spacings.grid_gap_basis,
  minHeight: spacings.grid_gap_basis_num * 4,
  ':hover': {
    backgroundColor: colors.backgrounds.teal,
  },
})

export default function RulesDndList({
  name,
  rules,
  setRules,
  defaultItems,
  labelOptions,
  criteriaOptions,
  rulePreferenceType,
  onAddRule,
  onRemoveRule,
  updateRule,
  errors,
}: IRulesDndListProps): JSX.Element {
  const [openPreferenceTypeDropdown, setOpenPreferenceTypeDropdown] =
    useState(false)
  // Handle closing dropdown when user clicks outside of the box
  const dropdownRef = useRef(null)

  /**
   * Closes dropdown when users clicks outside of the dropdown
   */
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenPreferenceTypeDropdown(false)
    }
  }

  /**
   * Add event listener to close dropdown when user clicks outside of the dropdown
   */
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <Container
      direction="column"
      alignment="start"
      css={{
        gap: spacings.grid_gap_basis_num * 2,
      }}>
      <SectionTitle>
        {rulePreferenceType === RulePreferenceType.rule
          ? 'rules'
          : 'preferences'}
      </SectionTitle>
      <DragAndDrop
        name={name}
        items={rules}
        setItems={setRules}
        defaultItems={defaultItems}
        css={dragAndDropStyle}
        showTitle
        draggableByIcon
        component={(index, item) => {
          if (item.group_type === RuleType.intergroup) {
            return (
              <IntergroupCustomRuleItem
                index={index}
                labelOptions={labelOptions}
                rule={item}
                errors={errors}
                onRemoveRule={() => onRemoveRule(index)}
                updateInterGroupRule={(preference, ruleData) =>
                  updateRule(index, preference, ruleData)
                }
              />
            )
          } else {
            return (
              <IntragroupCustomRuleItem
                index={index}
                labelOptions={labelOptions}
                criteriaOptions={criteriaOptions}
                rule={item}
                errors={errors}
                onRemoveRule={() => onRemoveRule(index)}
                updateIntraGroupRule={(preference, ruleData) =>
                  updateRule(index, preference, ruleData)
                }
              />
            )
          }
        }}
        numbered></DragAndDrop>
      <div>
        <Button
          type="button"
          as={ButtonComponentType.BUTTON}
          variant={Variant.LINK}
          startIcon={<PlusIcon css={plusIconStyle} />}
          onClick={() => setOpenPreferenceTypeDropdown(true)}>
          Add{' '}
          {rulePreferenceType === RulePreferenceType.rule
            ? 'rule'
            : 'preference'}
        </Button>
        {openPreferenceTypeDropdown && (
          <Container
            direction="column"
            ref={dropdownRef}
            css={dropdownBoxStyle}>
            <div
              key="add-rule-list-item-intergroup"
              css={listItemStyle}
              className="list-item"
              onClick={() => onAddRule(RuleType.intergroup)}>
              <Paragraph>Intergroup</Paragraph>
            </div>
            <div
              key="add-rule-list-item-intragroup"
              css={listItemStyle}
              className="list-item"
              onClick={() => onAddRule(RuleType.intragroup)}>
              <Paragraph>Intragroup</Paragraph>
            </div>
          </Container>
        )}
      </div>
    </Container>
  )
}
