import React from 'react'
import ReactSelect, { components, SingleValueProps } from 'react-select'
import type { getOptionLabel, getOptionValue } from 'react-select/src/builtins'
import cx from 'classnames'

import styles, { TOverrideStyles } from './styles'
import InputWrapper from '../InputWrapper/InputWrapper'
import { Caption, Paragraph } from 'components/Typography'

export interface IOption {
  label: string
  value: string
  description?: string
}

type TSelectValue = string | string[] | null

interface ISelect<T> {
  name: string
  options: T[]
  styleOverrides?: TOverrideStyles
  placeholder?: string
  noOptionsMessage?: string
  isMulti?: boolean
  required?: boolean
  readOnly?: boolean
  isClearable?: boolean
  showDescription?: boolean
  getOptionLabel?: getOptionLabel
  getOptionValue?: getOptionValue
  formatOptionLabel?: (option: T) => React.ReactNode
  getSelectedLabel?: (option: T) => string
  errors?: string[]
  subtextNode?: React.ReactNode
  id?: string
  className?: string
  disabled?: boolean
  isSearchable?: boolean
  onMenuOpen?: () => void
}

interface IControlledSelect<T> extends ISelect<T> {
  value: TSelectValue
  onChange: (value: Record<string, TSelectValue>) => void
}

interface IUncontrolledSelect<T> extends ISelect<T> {
  defaultValue: TSelectValue
  onChange?: (data: Record<string, TSelectValue>) => void
}

const Select = function <T extends IOption = IOption>({
  id,
  name,
  options,
  isMulti,
  required,
  noOptionsMessage,
  getSelectedLabel,
  showDescription = false,
  styleOverrides,
  onChange,
  className,
  errors = [],
  subtextNode = null,
  disabled,
  ...props
}: IControlledSelect<T> | IUncontrolledSelect<T>): React.ReactElement {
  const value = (props as IControlledSelect<T>).value
  const defaultValue = (props as IUncontrolledSelect<T>).defaultValue

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        {noOptionsMessage}
      </components.NoOptionsMessage>
    )
  }

  const SingleValue = getSelectedLabel
    ? (props: SingleValueProps<T>) => {
        return (
          <components.SingleValue {...props}>
            {getSelectedLabel(props.data)}
          </components.SingleValue>
        )
      }
    : components.SingleValue

  const getValue = (value: TSelectValue) => {
    if (defaultValue) return undefined
    const result = Array.isArray(value)
      ? options.filter((option) => value.indexOf(option.value) >= 0)
      : options.find((option) => option.value === value)
    return result
  }

  const getDefaultValue = (defaultValue: TSelectValue) => {
    if (defaultValue === null || defaultValue === undefined) return null

    const result = Array.isArray(defaultValue)
      ? options.filter((option) => defaultValue.indexOf(option.value) >= 0)
      : options.find((option) => option.value == defaultValue)

    return result
  }

  return (
    <InputWrapper errors={errors} subtextNode={subtextNode}>
      <input
        style={{ display: 'none' }}
        tabIndex={-1}
        className={'required-hack-input'}
        type="text"
        required={required}
        onChange={() => ({})}
        value={
          isMulti
            ? !!(value as string[] | number[])?.length
              ? 'true'
              : ''
            : value
            ? `${Boolean(value)}`
            : ''
        }
      />
      <ReactSelect
        {...props}
        id={id || name}
        name={name}
        menuPlacement="auto"
        menuPosition="fixed"
        options={options}
        isMulti={isMulti}
        classNamePrefix="react-select"
        styles={styles(styleOverrides)}
        defaultValue={getDefaultValue(defaultValue)}
        value={!defaultValue ? getValue(value) : undefined}
        onChange={
          !defaultValue
            ? (selectedOption: T | T[]) => {
                if (onChange) {
                  if (Array.isArray(selectedOption)) {
                    onChange({ [name]: selectedOption.map((o) => o.value) })
                  } else {
                    onChange({ [name]: selectedOption.value })
                  }
                }
              }
            : undefined
        }
        components={{
          NoOptionsMessage,
          SingleValue,
          ...(disabled && {
            DropdownIndicator: () => null,
          }),
          ...(showDescription && {
            Option: (props) => {
              return (
                <components.Option
                  {...props}
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                  <Paragraph>{props.data.label}</Paragraph>
                  <Caption className="option-description">
                    {props.data.description}
                  </Caption>
                </components.Option>
              )
            },
          }),
        }}
        className={cx('react-select', className, {
          'validation-error': errors.length,
        })}
        isDisabled={disabled}
      />
    </InputWrapper>
  )
}

export default Select
