import { ITableProps } from 'components/Table'
import ApplicantsTable from './ApplicantsTable'
import { useMentorshipExchangeDetailsContext } from './MentorshipExchangeDetailsContext'
import MatchSelectedIcon from 'images/icons/match_selected.svg'
import Button, { Variant } from 'components/Button'
import { colors, spacings } from 'stylesheets/theme'
import { useCallback, useState } from 'react'
import axios, { AxiosResponse } from 'axios'
import { StartMatchingModal } from './Modals'
import RematchSelectedModal from './Modals/MatchResultPageModals/RematchSelectedModal'
import Container from 'components/Container'
import TransferIcon from 'images/icons/transfer.svg'
import { IMentorshipExchange } from 'types'
import TransferApplicantsModal from './Modals/TransferApplicantsModal'
import ApplicationLabelsDropdown from './ApplicationLabelsDropdown'
import { css } from '@emotion/react'

interface INotYetMatchedTableProps {
  programs: IMentorshipExchange[]
  notYetMatchedTableProps: ITableProps
  programClosed?: boolean
  isShiftKeyDown?: boolean
  startMatchingUrl: string
  rematchSelectedUrl: string
  transferApplicationsUrl: string
}

interface IMentorshipStartRematchinResponse {
  success: boolean
}

interface IMentorshipTransferApplicationsResponse {
  success: boolean
  downloadable_file?: string
  errors?: string[]
}

const startMatchingButtonStyle = css({
  height: 30,
})

export default function NotYetMatchedTable({
  programs,
  notYetMatchedTableProps,
  programClosed = false,
  isShiftKeyDown = false,
  startMatchingUrl,
  rematchSelectedUrl,
  transferApplicationsUrl,
}: INotYetMatchedTableProps): JSX.Element {
  const { updateTableData, updateTableFilters, exchange, labels, urls } =
    useMentorshipExchangeDetailsContext()

  const [openStartMatchingModal, setOpenStartMatchingModal] = useState(false)
  const [openRematchSelectedModal, setRematchSelectedModal] = useState(false)
  const [openTransferSelectedModal, setTransferSelectedModal] = useState(false)
  const [rematchingStarted, setRematchingStarted] = useState(
    exchange.data.matching_status === 'processing' ||
      exchange.data.matching_status === 'processing_initiated',
  )
  const [transferApplicationsErrors, setTransferApplicationsErrors] = useState<
    string[]
  >([])
  const [transgerApplicationsDownloadLog, setTransferApplicationsDownloadLog] =
    useState<string | null>(null)

  const transferApplications = useCallback(
    (selectedProgram, selectedApplicantIds = []) => {
      setTransferApplicationsErrors([])
      setTransferApplicationsDownloadLog(null)
      const params = {
        from_program_id: exchange.data.id,
        target_program_id: selectedProgram.id,
        application_ids: selectedApplicantIds,
      }
      axios
        .post(transferApplicationsUrl, {
          ...params,
          authenticity_token: window.authenticity_token,
        })
        .catch(() => {
          window.flash('Could not transfer selected applicants.', 'error')
        })
        .then(
          ({
            data: { success, downloadable_file, errors },
          }: AxiosResponse<IMentorshipTransferApplicationsResponse>) => {
            if (success) {
              window.flash(
                `Success! Applicants have been transferred to ${selectedProgram.full_name_with_index}`,
                'success',
              )
              setTransferSelectedModal(false)
            } else {
              if (errors) {
                setTransferApplicationsErrors(errors)
              }

              if (downloadable_file) {
                setTransferApplicationsDownloadLog(downloadable_file)
              }
            }
          },
        )
    },
    [setTransferApplicationsErrors, exchange],
  )

  // Handle rematching request
  const startRematching = (selectedApplicantIds = []) => {
    if (!isShiftKeyDown && rematchingStarted) return
    const params = { id: exchange.data.id }
    setRematchingStarted(true)
    // TODO: simplify this to onAction(url, params)
    if (selectedApplicantIds.length > 0) {
      axios
        .post(rematchSelectedUrl, {
          ...params,
          ids: selectedApplicantIds,
          authenticity_token: window.authenticity_token,
        })
        .catch(() => {
          setRematchingStarted(false)
          window.flash(
            'Could not start rematching selected applicants.',
            'error',
          )
        })
        .then(
          ({
            data: { success },
          }: AxiosResponse<IMentorshipStartRematchinResponse>) => {
            if (success) {
              window.location.reload()
            } else {
              setRematchingStarted(false)
              window.flash(
                'Could not start rematching selected applicants.',
                'error',
              )
            }
          },
        )
    } else {
      axios
        .post(startMatchingUrl, {
          params,
          authenticity_token: window.authenticity_token,
        })
        .catch(() => {
          setRematchingStarted(false)
          window.flash('Could not start rematching.', 'error')
        })
        .then(
          ({
            data: { success },
          }: AxiosResponse<IMentorshipStartRematchinResponse>) => {
            if (success) {
              window.location.reload()
            } else {
              setRematchingStarted(false)
              window.flash('Could not start rematching.', 'error')
            }
          },
        )
    }

    setRematchSelectedModal(false)
    setOpenStartMatchingModal(false)
  }
  return (
    <>
      <ApplicantsTable
        {...notYetMatchedTableProps}
        css={{
          '.GojiCustomTable thead tr th:first-of-type': {
            width: `calc(${spacings.grid_gap_basis} * 2 + 15px + ${spacings.grid_gap_basis} * 2)`,
          },
          '.GojiCustomTable thead tr th:last-of-type': {
            width: '10%',
          },
        }}
        includeFilters
        selectable
        showSelectedRowsCount
        tableDescription="applicants"
        afterUpdateTableData={(data) => {
          updateTableData('not_yet_matched', data)
        }}
        afterUpdateSelectedFilters={(filters) => {
          updateTableFilters('not_yet_matched', filters)
        }}
        tertiaryActionNode={({ selectedRows }) => (
          <Container>
            <ApplicationLabelsDropdown
              defaultLabels={labels}
              createLabelsLink={urls.create_application_label}
              editLabelsLink={urls.edit_application_labels}
              assignApplicationLabelLink={urls.assign_program_application_label}
              searchLabelsLink={urls.serach_application_labels}
              updateTableData={(data) =>
                updateTableData('not_yet_matched', data)
              }
              selectedRows={selectedRows}
              table="not_yet_matched"
            />
            <Button
              startIcon={
                <MatchSelectedIcon
                  css={{
                    path: {
                      fill: colors.links.blue,
                    },
                    marginRight: spacings.grid_gap_basis_num / 2,
                  }}
                />
              }
              onClick={() => setRematchSelectedModal(true)}
              variant={Variant.LINK}
              css={{
                color: colors.links.blue,
              }}
              disabled={selectedRows.length <= 1}>
              Match selected applicants
            </Button>
            <Button
              startIcon={
                <TransferIcon
                  css={{
                    path: {
                      fill: colors.links.blue,
                    },
                    marginRight: spacings.grid_gap_basis_num / 2,
                  }}
                />
              }
              onClick={() => setTransferSelectedModal(true)}
              variant={Variant.LINK}
              css={{
                color: colors.links.blue,
              }}
              disabled={selectedRows.length == 0}>
              Transfer selected applicants
            </Button>
            <RematchSelectedModal
              isOpen={openRematchSelectedModal}
              isSubmitDisabled={rematchingStarted && !isShiftKeyDown}
              onSave={() => startRematching(selectedRows.map((row) => row.id))}
              onRequestClose={() => setRematchSelectedModal(false)}
            />
            <TransferApplicantsModal
              isOpen={openTransferSelectedModal}
              selectedCount={selectedRows.length}
              programs={programs}
              onSave={(selectedProgram) =>
                transferApplications(
                  selectedProgram,
                  selectedRows.map((row) => row.id),
                )
              }
              onRequestClose={() => setTransferSelectedModal(false)}
              errors={transferApplicationsErrors}
              downloadLogCsv={transgerApplicationsDownloadLog}
            />
          </Container>
        )}
        secondaryActionNode={
          programClosed ? undefined : (
            <Button
              variant={Variant.SECONDARY}
              small
              css={startMatchingButtonStyle}
              disabled={
                !isShiftKeyDown &&
                (rematchingStarted ||
                  notYetMatchedTableProps.tableData.rows.length === 0)
              }
              onClick={() => setOpenStartMatchingModal(true)}>
              {rematchingStarted ? 'rematch started...' : 'start matching'}
            </Button>
          )
        }
        emptyStateChildren={
          <p css={{ fontWeight: 'normal' }}>All applicants have been matched</p>
        }
      />
      <StartMatchingModal
        isOpen={openStartMatchingModal}
        isSubmitDisabled={rematchingStarted && !isShiftKeyDown}
        onSave={() => startRematching()}
        onRequestClose={() => setOpenStartMatchingModal(false)}
      />
    </>
  )
}
