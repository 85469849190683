import cx from 'classnames'
import { css } from '@emotion/react'
import Table, { ITableProps } from 'components/Table'

import emptyStateImage from 'images/people_empty_state.png'
import MembershipFilters, {
  IMembershipFiltersProps,
} from 'components/MembershipFilters'
import useUsersState from 'state/Users'
import { SectionTitle } from 'components/Typography'
import DownloadIcon from '../../images/icons/download.svg'
import Container from 'components/Container'
import { colors, spacings } from 'stylesheets/theme'
import { useMemo } from 'react'

const emptyStateTableCss = css({
  '.empty-state-wrapper': {
    paddingTop: 89,
    paddingBottom: 89,
  },
})

const sectionTableTitleStyle = css({
  marginRight: 7,
  color: colors.text.text_3,
  lineHeight: '22px',
})

const exportUrlStyle = css({
  marginLeft: 8,
  svg: {
    width: 14,
    height: 14,
    marginRight: 4,
  },
})

interface IApplicantsTableProps extends ITableProps {
  includeFilters?: boolean
  filterOptions?: IMembershipFiltersProps['filterOptions']
}

export default function ApplicantsTable({
  includeFilters = false,
  filterOptions,
  title,
  tableData,
  tableMeta,
  className,
  ...tableProps
}: IApplicantsTableProps): JSX.Element {
  const {
    data,
    meta,
    filters: selectedFilters,
    updateFilters,
  } = useUsersState({
    url: tableMeta.url,
    initialData: tableData,
    initialMeta: tableMeta,
    initialCounters: {},
    initialFilters: {},
  })

  const classes = cx(
    {
      'applicants-table': true,
      'no-pagination': !!tableData?.paginator,
    },
    className,
  )

  const secondaryActionNode = useMemo(() => {
    if (typeof tableProps.secondaryActionNode === 'function') {
      return tableProps.secondaryActionNode({}) as JSX.Element
    }
    return tableProps.secondaryActionNode as JSX.Element
  }, [tableProps.secondaryActionNode])

  if (includeFilters) {
    return (
      <div className={classes}>
        <Container
          css={{
            marginBottom: spacings.grid_gap_basis_num * 2,
            gap: 0,
            justifyContent: 'space-between',
          }}>
          <Container>
            <SectionTitle css={sectionTableTitleStyle}>
              {title ?? 'Applicants'}
            </SectionTitle>
            <a
              href={tableMeta.url.replace('.json', '.csv')}
              className="link"
              css={exportUrlStyle}>
              <DownloadIcon />
              Export.csv
            </a>
          </Container>
          {secondaryActionNode}
        </Container>
        <MembershipFilters
          showAllFilters
          filterOptions={filterOptions}
          updateFilters={updateFilters}
          filters={selectedFilters}
        />
        <Table
          {...tableProps}
          tableData={data}
          tableMeta={meta}
          selectedFilters={selectedFilters}
          emptyStateImgUrl={emptyStateImage}
          css={emptyStateTableCss}
        />
        <div className="applicants-table-pagination"></div>
      </div>
    )
  } else {
    return (
      <div className={classes}>
        <Table
          {...tableProps}
          tableData={data}
          tableMeta={meta}
          selectedFilters={selectedFilters}
          title={title ?? 'Applicants'}
          emptyStateImgUrl={emptyStateImage}
          css={emptyStateTableCss}
        />
        <div className="applicants-table-pagination"></div>
      </div>
    )
  }
}
